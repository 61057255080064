import { call, put, select } from 'redux-saga/effects';
import { redirect } from 'redux-first-router';
import moment from 'moment';
import { SimpleActionType } from '../utils/storeUtils';
import { sagas as authSagas, selectors as authSelectors } from './auth';
import { fetchOriginalComposerEntities } from './originalComposer';
import {
  fetchExportablePROsSelect,
  fetchPROSongRegistrationExports,
} from './proSongRegistrations';
import { fetchPublisherRulesetEntities } from './publisherRuleset';
import {
  fetchPublisherConstants,
  fetchPublishers,
  fetchSinglePublisher,
} from './publishers';
import {
  fetchSingleSongCollection,
  fetchSongCollectionEntities,
  fetchSongCollections,
} from './songCollections';
import { fetchSongGenreEntities } from './songGenre';
import { fetchSongInstrumentEntities } from './songInstrument';
import {
  fetchSongConstants,
  fetchSongs,
  fetchCurationStateOptions,
} from './songs';
import {
  initSingleSong,
  fetchSingleSong,
  clearSongData,
  pollSourceFiles,
  stopSourceFilesPolling,
  playSourceFileOnLoad,
} from './singleSong';
import { fetchSongTags } from './songTags';
import { fetchSongHiddenTags } from './songTagsHidden';
import { fetchTaxonomyTags } from './taxonomyTags';
import { fetchArtistSubmissions } from './artistSubmissions';
import {
  fetchSingleTrackSubmission,
  initSingleTrackSubmission,
} from './singleTrackSubmission';
import {
  fetchSingleArtistSubmission,
  initSingleArtistSubmission,
} from './singleArtistSubmission';
import { fetchSongThemeEntities } from './songTheme';
import { fetchSingleUser } from './users';
import { fetchSalesBreakdown } from './salesBreakdown';
import { fetchSalesTotal } from './salesTotal';
import { fetchMyWriters, clearSingleMyWriterData } from './myWriters';
import { fetchMyProfile } from './myProfile';
import { clearSongFinderData } from './songFinder';
import { invalidateNovaQueries } from 'novaApi/useNovaApi';

// TODO: rename useSearchSaga into isSearchOnly
const useSearchSaga = (action?: SimpleActionType): boolean => {
  // check if it's an action dispatch to update the search/filters or to go to the previous page
  return (
    action?.payload?.useSearchSaga || action?.meta?.location?.kind === 'pop'
  );
};
function* onPublisherRoute(action: SimpleActionType) {
  yield put(fetchPublishers());
}

function* onPublisherCreateRoute() {
  yield put(fetchPublisherConstants());
}

function* onPublisherEditRoute(action: SimpleActionType) {
  yield put(fetchSinglePublisher(action));
  yield put(fetchPublisherConstants());
}

function* onPROSongRegistrationRoute() {
  yield put(fetchPROSongRegistrationExports());
  yield put(fetchExportablePROsSelect());
}

function* onUserEditRoute(action: SimpleActionType) {
  yield put(fetchSingleUser(action.payload));
}

function* onSongRoute(action: SimpleActionType) {
  const canGetSongCollections: boolean = yield select((state) =>
    authSelectors.isUserAllowed(state, 'CAN_GET_SONG_COLLECTIONS'),
  );

  yield put(fetchSongs());
  const isSearch: boolean = yield call(useSearchSaga, action);
  if (!isSearch) {
    yield put(fetchSongConstants());
    yield put(fetchCurationStateOptions());
    yield put(fetchSongGenreEntities());
    if (canGetSongCollections) {
      yield put(fetchSongCollectionEntities());
    }
    yield put(fetchSongInstrumentEntities());
    yield put(fetchSongThemeEntities());
    yield put(fetchSongTags());
    yield put(fetchSongHiddenTags());
    yield put(fetchTaxonomyTags());
  }
}

function* onSongCreateRoute() {
  // no need to check the permitions here because a user
  // that can display the create song page can load all the data
  invalidateNovaQueries('/artist/select');
  yield put(fetchSongConstants());
  yield put(fetchSongCollectionEntities());
  yield put(fetchPublisherRulesetEntities());
  yield put(fetchSongGenreEntities());
  yield put(fetchSongThemeEntities());
  yield put(fetchSongInstrumentEntities());
  yield put(fetchSongTags());
  yield put(fetchSongHiddenTags());
  yield put(fetchOriginalComposerEntities());
}

function* onSongEditRoute(action: SimpleActionType) {
  yield put(initSingleSong());
  const canGetSongCollections: boolean = yield select((state) =>
    authSelectors.isUserAllowed(state, 'CAN_GET_SONG_COLLECTIONS'),
  );
  const canGetPublisherRulesets: boolean = yield select((state) =>
    authSelectors.isUserAllowed(state, 'CAN_GET_PUBLISHER_RULESETS'),
  );
  const canGetOriginalComposers: boolean = yield select((state) =>
    authSelectors.isUserAllowed(state, 'CAN_GET_ORIGINAL_COMPOSERS'),
  );
  yield put(fetchSingleSong({ uuid: action.payload.uuid }));
  yield put(fetchSongConstants());
  if (canGetSongCollections) {
    yield put(fetchSongCollectionEntities());
  }
  if (canGetPublisherRulesets) {
    yield put(fetchPublisherRulesetEntities());
  }
  yield put(fetchCurationStateOptions());
  yield put(fetchSongGenreEntities());
  yield put(fetchSongThemeEntities());
  yield put(fetchSongInstrumentEntities());
  yield put(fetchSongTags());
  yield put(fetchSongHiddenTags());
  if (canGetOriginalComposers) {
    yield put(fetchOriginalComposerEntities());
  }
  yield put(pollSourceFiles({ songUuid: action.payload.uuid }));

  // This saga is run to play a source file if a source_file_uuid is specified in the url
  yield put(playSourceFileOnLoad());
}

function* onSongEditLeave(action: SimpleActionType) {
  yield put(clearSongData());
  yield put(stopSourceFilesPolling({ songUuid: action.payload.uuid }));
  yield put(clearSongFinderData());
}

function* onSongFinderLeave() {
  yield put(clearSongFinderData());
}

function* onSongCollectionRoute() {
  yield put(fetchSongCollections());
}

function* onSongCollectionEditRoute(action: SimpleActionType) {
  yield put(fetchSingleSongCollection(action));
}

function* onArtistSubmissionsRoute() {
  yield put(fetchArtistSubmissions());
}

function* onArtistSubmissionEditRoute(action: SimpleActionType) {
  yield put(initSingleArtistSubmission());
  yield put(fetchSingleArtistSubmission({ uuid: action.payload.uuid }));
}

function* onTrackSubmissionEditRoute(action: SimpleActionType) {
  yield put(initSingleTrackSubmission());
  yield put(fetchSingleTrackSubmission({ uuid: action.payload.uuid }));
}

function* onMyTrackEditRoute(action: SimpleActionType) {
  yield put(initSingleTrackSubmission());
  yield put(fetchSingleTrackSubmission({ uuid: action.payload.uuid }));
  // no route exists for fetching my_writers/select to populate the drop-down
  // use GET /my_writer with a large pagesize - ignore url query params
  // artists cannot create more than 4 writers on their profile anyway
  yield put(fetchMyWriters({ pagenum: 1, pagesize: 100 }));
}

function* onMyTrackCreateRoute() {
  yield put(initSingleTrackSubmission());
}

function* onEarningsSummary() {
  // If the start date or the end date are not selected
  const query: Nl.QueryFilter = yield select((state) => state.location.query);
  if (!query || !query.start_date || !query.end_date) {
    // Then set the filters start_date and/or end_date to the default value
    yield put(
      redirect({
        type: 'route/DASHBOARD_SALES_REPORTS',
        payload: {
          query: {
            // @ts-ignore
            start_date:
              query?.start_date ||
              moment.utc(moment()).subtract(7, 'days').startOf('day').valueOf(),
            // @ts-ignore
            end_date: query?.end_date || moment().valueOf(),
          },
        },
      }),
    );
  } else {
    yield put(fetchSalesBreakdown());
    yield put(fetchSalesTotal());
  }
}

function* onMyWritersRoute() {
  yield put(fetchMyProfile());
  yield put(fetchMyWriters());
}

function* onMyWriterCreateLeave() {
  yield put(clearSingleMyWriterData());
}

function* onMyProfileEditRoute() {
  yield put(fetchMyProfile());
}

export default {
  onLogout: authSagas.onLogoutSaga,
  onPublisherRoute,
  onPublisherCreateRoute,
  onPublisherEditRoute,
  onPROSongRegistrationRoute,
  onUserEditRoute,
  onSongRoute,
  onSongCreateRoute,
  onSongEditRoute,
  onSongEditLeave,
  onSongFinderLeave,
  onSongCollectionRoute,
  onSongCollectionEditRoute,
  onArtistSubmissionsRoute,
  onTrackSubmissionEditRoute,
  onArtistSubmissionEditRoute,
  onMyTrackEditRoute,
  onMyTrackCreateRoute,
  onEarningsSummary,
  onMyWritersRoute,
  onMyWriterCreateLeave,
  onMyProfileEditRoute,
};
